import { connect } from 'react-redux';
import CatatHarianSiswa from '../../../views/Student/displiner/CatatHarianSiswa.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses, getParamOptions, getSubjectOptions, getParamOptionsAcademics, getPeriodsOptions, getStudentOptions, getPrmAspectOptions, getTeacherOptions, getPrmLevels} from '../../../states/thunks/options.thunk';
import { getRecordDaily, saveRecordDaily, listRecordDaily, editRecordDaily} from '../../../states/thunks/academics.thunk'

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    user: state.user,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleGetClasses: payload => dispatch(getClasses(payload)),
    getSubjectOptions: (payload) => dispatch(getSubjectOptions(payload)),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleGetStudent: () => dispatch(getStudent()),
    handleGetRecordDaily: payload => dispatch(getRecordDaily(payload)),
    handleSaveRecordDaily: (payload, goBack) => dispatch(saveRecordDaily(payload, goBack)),    
    handleListRecordDaily: (payload, goBack) => dispatch(listRecordDaily(payload, goBack)),
    handleEditRecordDaily: (payload, goBack) => dispatch(editRecordDaily(payload, goBack)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    getStudentOptions: (payload) => dispatch(getStudentOptions(payload)),
    getPrmAspectOptions: (payload) => dispatch(getPrmAspectOptions(payload)),
    getTeacherOptions: (payload) => dispatch(getTeacherOptions(payload)),
    getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CatatHarianSiswa);


