import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map, isEqual, capitalize } from "lodash";
import {
  commaFormatted,
  normalizeAmount,
} from "../../../../utils/transformer.util";
import { Select, Button, SearchSelect } from "../../../../components/base/index";
import ReactToPrint from "react-to-print";
import logo from '../../../../assets/img/logo-YPL.png';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class Tunggakan extends PureComponent {
  constructor(props) {
    super(props);
    
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._getYearsOfEducationsOptions = this._getYearsOfEducationsOptions.bind(this);
    // this._getStudentOptions = this._getStudentOptions.bind(this);
    this._getMsTempsSiswaOptions = this._getMsTempsSiswaOptions.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    const { user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    this.state = {
      page: 1,
      content: initialContent,
      filters:{},
      isPrint: true,
      form: {
        value: {
          levels_id,
        },
        error: {
        },
      },
      param: {},
    };
  }

  componentDidMount() {
    const { user } = this.props;
    const { school_unit, organizations_id } = user;
    const { levels_id } = school_unit;
    const { content, filters, form } = this.state;
    const { value } = form;
    
    const paramTypes = ["classrooms"];
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    this._getYearsOfEducationsOptions({units_id: school_unit.id });
    this._getPeriodsOptions({units_id: school_unit.id});
    // this._getStudentOptions({units_id: school_unit.id});
    this._getMsTempsSiswaOptions({units_id: school_unit.va_code});

  }

  _onChangeFilter(e) {
    const { user } = this.props;
    const { organizations_id } = user;
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        this._onSearchContent();
      }
    );
  }

  async _onSearchContent() {
    const { filters, form, savedSiswa, savedKelas, savedAkumulasi } = this.state;
    const { tunggakan, classrooms_id, period, period2, students_id } = filters;
    const { currents_year, currents_year2, year } = form.value
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const { handleListArrearsStudents, handleListArrearsClasses, handleListArrearsAccumulations } = this.props;
          const { user } = this.props;
          const { school_unit, organizations_id} = user;
          const units_id = school_unit.id;

          let listname = ""
          let result = {};

          if (tunggakan === "siswa" && students_id) {
            if (!(savedSiswa && savedSiswa.students_id == students_id)) {
              this.setState((prevState) => ({
                ...prevState,
                savedSiswa: {
                  students_id: students_id
                },
              }));
              listname = "tunggakansiswa"
              result = await handleListArrearsStudents({
                students_id : students_id.value,
                currents_year : year,
                organizations_id : organizations_id,
              });
            }
          } 
          else if (tunggakan === "kelas" && period && classrooms_id) {
            if (!(savedKelas && savedKelas.period == period && savedKelas.classrooms_id == classrooms_id)) {
              this.setState((prevState) => ({
                ...prevState,
                savedKelas: {
                  period : period,
                  classrooms_id : classrooms_id,
                },
              }));
              listname = "tunggakankelas"
              result = await handleListArrearsClasses({
                // currents_year : period,
                period : period,
                currents_year : currents_year ? currents_year : year,
                classrooms_id : classrooms_id,
                organizations_id : organizations_id,
                filters
              });
            }
          }
          else if (tunggakan === "akumulasi" && period2) {
            if (!(savedAkumulasi && savedAkumulasi.period2 == period2)) {
              this.setState((prevState) => ({
                ...prevState,
                savedAkumulasi: {
                  period2: period2
                },
              }));
              listname = "tunggakanakumulasi"
              result = await handleListArrearsAccumulations({
                period2 : period2,
                currents_year : currents_year2 ? currents_year2 : year,
                units_id : units_id,
                organizations_id : organizations_id,
                filters : {period2: period2}
              });
            }
          }
          
          if (listname != "") {
            this.setState((prevState) => ({
              ...prevState,
              form: {
                ...prevState.form,
                value: {
                  ...prevState.form.value,
                  [listname]: result,
                },
              },
            }));
          }
        } catch (err) {
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                tunggakansiswa: [],
                tunggakankelas: [],
                tunggakanakumulasi: [],
              },
            },
          }));
        }
        // finally {
        //   commonActions.setLoading({ show: false }); 
        // }
      }
    );
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit } = user;
    // const { levels_id } = school_unit;

    if (type === "classrooms") {
      filters.organizations_id = organizations_id;
      filters.units_id = school_unit.id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getYearsOfEducationsOptions(filters = {}) {
    const { getYearsOfEducationsOptions } = this.props;
    const res = await getYearsOfEducationsOptions(filters);

    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date)
      if (date > start_date) {
        // tahun ajaran sekarang
        this.setState(prevState => ({
          ...prevState,
          form: {
            ...prevState.form,
            value : {
              ...prevState.form.value,
              year: data.value,
            }
          },
        }))
      }
    });

    // this.setState((prevState) => ({
    //   ...prevState,
    //   param: {
    //     ...prevState.param,
    //     periods: res,
    //   },
    // }));
  }

  async _getPeriodsOptions(filters = {}) {
    const { getPeriodsOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPeriodsOptions(filters);
    filters.organizations_id = 3;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  // async _getStudentOptions(filters = {}) {
  //   const { getStudentOptions } = this.props;
  //   const res = await getStudentOptions(filters);
  //   this.setState((prevState) => ({
  //     ...prevState,
  //     param: {
  //       ...prevState.param,
  //       student: res,
  //     },
  //   }));
  // }
  async _getMsTempsSiswaOptions(filters = {}) {
    const { getMsTempsSiswaOptions } = this.props;
    const res = await getMsTempsSiswaOptions(filters);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
  }

  render() {
    const { form, filters, param, page, isPrint, data_idx } = this.state;
    const { classrooms_id = "", tunggakan, period = "", period2 = "", students_id } = filters;
    const { tunggakansiswa, tunggakankelas, tunggakanakumulasi } = form.value
    const { user } = this.props
    const { school_unit } = user;

    const componentRef = React.createRef();
    
    const printTest = `
    @media print {
      @page {
        page-break-inside: avoid;
      }
      * {
        -webkit-print-color-adjust: exact;
      }
    }`;

    const prm_tunggakan = [
      { label: 'Siswa', value: 'siswa'},
      { label: 'Kelas', value: 'kelas'},
      { label: 'Akumulasi', value: 'akumulasi'},
    ];

    var ttl_siswaNonRutin = 0;
    var ttl_siswaThnLalu = 0;
    var ttl_siswaRutin = 0;
    if(!isEmpty(tunggakansiswa)) {
      ttl_siswaThnLalu = !isEmpty(tunggakansiswa[0].arrears_students_last_year) ? ((tunggakansiswa[0].arrears_students_last_year[0].total_tunggakan_last_year)) : 0;
    }
    let student_name =""
    let student_va =""
    if (!isEmpty(students_id)){
      let str=students_id.label
      let result = str.indexOf("-");
      student_name = str.substring(result+2, str.length)
      student_va = str.substring(0, result-1)
    }

    var ttl_klsThnlalu = 0;
    var ttl_klsDPP = 0;
    var ttl_klsUPP = 0;
    var ttl_klsUPA = 0;
    var ttl_klsSPP = 0;
    var ttl_kls = 0;

    var ttl_akmThnlalu = 0;
    var ttl_akmDPP = 0;
    var ttl_akmUPP = 0;
    var ttl_akmUPA = 0;
    var ttl_akmSPP = 0;
    var ttl_akm = 0;

    if(period) {
      let period_label = param.periods.find((i) => i.value == period) ? param.periods.find((i) => i.value == period).label : ""
      if (period_label && period_label != "") {
        let period_idx = period_label.indexOf("-");
        form.value.currents_year = period_label.substring(0, period_idx)
      }
    }
    if(period2) {
      let period_label = param.periods.find((i) => i.value == period2) ? param.periods.find((i) => i.value == period2).label : ""
      if (period_label && period_label != "") {
        let period_idx = period_label.indexOf("-");
        form.value.currents_year2 = period_label.substring(0, period_idx)
      }
    }

    return (
      <div className="student-list">
        <div className="budget__title">
          <h1>Tunggakan</h1>
          <hr></hr>
          <br></br>
        </div>

        <div className="absensi-rekap__custom-form">
          <div className="manage-registration__custom-form-row">
            <div className="manage-registration__custom-form-row__field">
              <Select
                name="tunggakan"
                label="Tunggakan"
                onChange={this._onChangeFilter}
                data={prm_tunggakan}
                placeholder="Pilihan"
                value={tunggakan}
              />
            </div>
            <div className="manage-registration__custom-form-row__field">
            </div>
          </div>
          <hr></hr>
          <br></br>

          {(tunggakan && tunggakan != "") && (
            <div className="beginning-balance__button-wrapper">
              <ReactToPrint
                pageStyle={printTest}
                onBeforeGetContent={()=> {
                  return new Promise((resolve) => {
                    setTimeout(() => {
                      this.setState(
                        { isPrint: false },
                        resolve
                      );
                    }, 500);
                  });
                }}
                onAfterPrint={()=> this.setState({isPrint: true})}
                trigger={() => (
                  <Button title="Cetak" />
                )}
                content={() => componentRef.current}
              />
            </div>
          )}

          <br></br>
          {tunggakan == "siswa" && (
            <div className="manage-registration__custom-form-row">
              <div className="manage-registration__custom-form-row__field">
                <SearchSelect
                  noMargin
                  async={false}
                  name="students_id"
                  list={param.student}
                  inputArray
                  onClick={this._onChangeFilter}
                  placeholder="Pilih Siswa"
                  value={students_id}
                  rightIcon="icon-search"
                  label="Siswa"
                />
              </div>
            </div>
          )}

          {tunggakan == "kelas" && (
            <div className="manage-registration__custom-form-row">
              <div className="manage-registration__custom-form-row__field">
                <Select
                  name="period"
                  label="Tahun Ajaran"
                  onChange={this._onChangeFilter}
                  data={param.periods}
                  placeholder={"Pilihan"}
                  value={period}
                />
              </div>
              <div className="manage-registration__custom-form-row__field">
                <Select
                  type="text"
                  name="classrooms_id"
                  label="Kelas"
                  placeholder="Pilih Kelas"
                  data={param.classrooms}
                  onChange={this._onChangeFilter}
                  value={classrooms_id}
                />
              </div>
            </div>
          )}

          {tunggakan == "akumulasi" && (
            <div className="manage-registration__custom-form-row">
              <div className="manage-registration__custom-form-row__field">
                <Select
                  name="period2"
                  label="Tahun Ajaran"
                  onChange={this._onChangeFilter}
                  data={param.periods}
                  placeholder={"Pilihan"}
                  value={period2}
                />
              </div>
              <div className="manage-registration__custom-form-row__field">
              </div>
            </div>
          )}
          <br></br>
        </div>

        <form style={{ pageBreakInside: 'avoid' }} ref={componentRef}>
          {(tunggakan=="siswa" && !isEmpty(tunggakansiswa)) && (
            <div className="cetak-buku-induk2__header-title3">
              <div className="cetak-buku-induk2__header-title3--left">
                {!isPrint && (
                  <>
                    <table className="table-report-header">
                      <tr>
                        <th rowSpan="2">
                          <img style={{width:"70px"}} src={logo} />
                        </th>
                        <th style={{ paddingRight: '10rem' }}>
                          <h2>{school_unit.name}</h2>
                        </th>
                      </tr>
                      <tr>
                        <th style={{ paddingRight: '10rem' }}>
                          <h3>Tunggakan Siswa</h3>
                        </th>
                      </tr>
                    </table>
                    <table className="laporan-ki4__table-test" style={{width:"unset"}}>
                      <tr>
                        <td>No. VA </td>
                        <td>&emsp; : {student_va}</td>
                      </tr>
                      <tr>
                        <td>Nama Siswa</td>
                        <td>&emsp; : {student_name}</td>
                      </tr>
                    </table>
                  </>
                )}
                <br></br>
                <table className="cetak-buku-induk2__table-test" style={{width:"unset"}}>
                  <tr style={{fontWeight:"bold"}}>
                    <td colSpan={5}>
                      <div className="cetak-buku-induk2__title">
                        <p>Tagihan Sekali Setahun</p>
                      </div>
                    </td>
                  </tr>
                  {!isEmpty(tunggakansiswa[0].arrears_students_non_rutin) && (
                    map(tunggakansiswa[0].arrears_students_non_rutin, (list, idx) => (
                      <tr>
                        {(() => {
                          ttl_siswaNonRutin = ttl_siswaNonRutin + Number(list.total_tunggakan)
                        })()}
                        <td>- </td>
                        <td colSpan={2}>{list.nick_name} </td>
                        <td>Rp. &emsp;</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.total_tunggakan)}</td>
                      </tr>
                    ))
                  )}
                  <tr style={{fontWeight:"bold"}}>
                    <td></td>
                    <td></td>
                    <td>
                      <div className="cetak-buku-induk2__title">
                        <p>&emsp; Jumlah Tunggakan Sekali Setahun &emsp;&emsp;</p>
                      </div>
                    </td>
                    <td>Rp. &emsp;</td>
                    <td style={{textAlign:"right"}}>{commaFormatted(ttl_siswaNonRutin)}</td>
                  </tr>
                  <br></br>
                  
                  <tr style={{fontWeight:"bold"}}>
                    <td colSpan={5}>
                      <div className="cetak-buku-induk2__title">
                        <p>Tagihan Rutin Bulanan</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>a. </td>
                    <td colSpan={2}>Akumulasi Tahun Ajaran Sebelumnya &emsp;&emsp;</td>
                    <td>Rp. &emsp;</td>
                    <td style={{textAlign:"right"}}>
                      {commaFormatted(ttl_siswaThnLalu)}
                    </td>
                  </tr>
                  <tr>
                    <td>b. </td>
                    <td colSpan={4}>Akumulasi Tahun Ajaran Berjalan &emsp;&emsp;</td>
                  </tr>
                  {!isEmpty(tunggakansiswa[0].arrears_students_rutin) && (
                    map(tunggakansiswa[0].arrears_students_rutin, (list, idx) => (
                      <tr>
                        {(() => {
                          if (list.payments_date != null) {
                            list.nominal = 0;
                          }
                          ttl_siswaRutin = ttl_siswaRutin + Number(list.nominal);
                        })()}
                        <td></td>
                        <td>- </td>
                        <td>SPP {list.month} </td>
                        <td>Rp. &emsp;</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.nominal)}</td>
                      </tr>
                    ))
                  )}
                  <tr style={{fontWeight:"bold"}}>
                    <td></td>
                    <td></td>
                    <td>
                      <div className="cetak-buku-induk2__title">
                        <p>&emsp; Jumlah Tunggakan Rutin Bulanan &emsp;&emsp;</p>
                      </div>
                    </td>
                    <td>Rp. &emsp;</td>
                    <td style={{textAlign:"right"}}>{commaFormatted(ttl_siswaRutin)}</td>
                  </tr>
                  <tr style={{fontWeight:"bold"}}>
                    <td></td>
                    <td></td>
                    <td>
                      <div className="cetak-buku-induk2__title">
                        <p>&emsp; Total Tunggakan &emsp;&emsp;</p>
                      </div>
                    </td>
                    <td>Rp. &emsp;</td>
                    <td style={{textAlign:"right"}}>{commaFormatted(Number(ttl_siswaNonRutin)+Number(ttl_siswaThnLalu)+Number(ttl_siswaRutin))}</td>
                  </tr>
                  <br></br>
                  
                </table>
              </div>
            </div>
          )}
          {(tunggakan=="kelas" && !isEmpty(tunggakankelas)) && (
            <div className="cetak-buku-induk2__header-title3">
              <div className="cetak-buku-induk2__header-title3--left" style={{marginLeft:"unset"}}>
                {!isPrint && (
                  <>
                    <table className="table-report-header">
                      <tr>
                        <th rowSpan="2">
                          <img style={{width:"70px"}} src={logo} />
                        </th>
                        <th style={{ paddingRight: '10rem' }}>
                          <h2>{school_unit.name}</h2>
                        </th>
                      </tr>
                      <tr>
                        <th style={{ paddingRight: '10rem' }}>
                          <h3>Tunggakan Kelas</h3>
                        </th>
                      </tr>
                    </table>
                    <table className="laporan-ki4__table-test" style={{width:"unset"}}>
                      <tr>
                        <td>Tahun Ajaran </td>
                        {/* <td>&emsp; : {period+"/"+(Number(period)+1)}</td> */}
                        <td>&emsp; : {param.periods.find((i) => i.value == period) ? param.periods.find((i) => i.value == period).label : ""}</td>
                      </tr>
                      <tr>
                        <td>Kelas</td>
                        <td>&emsp; : {param.classrooms.find((i) => i.value == classrooms_id).label}</td>
                      </tr>
                    </table>
                  </>
                )}
                <br></br>
                <table className="tablespp">
                  <thead>
                    <tr>
                      <th rowSpan={2}>No</th>
                      <th rowSpan={2}>No. VA</th>
                      <th rowSpan={2}>Nama</th>
                      <th colSpan={6}>Tunggakan</th>
                    </tr>
                    <tr>
                      <th>Σ Tahun Lalu</th>
                      <th>DPP</th>
                      <th>UPP</th>
                      <th>UPA</th>
                      <th>SPP</th>
                      <th>Jumlah</th>
                    </tr>
                  </thead>
                  <tbody>
                    {map(tunggakankelas, (list, idx) => (
                      <tr>
                        {(() => {
                          list.thnlalu = list.arrears_students_last_year[0] ? list.arrears_students_last_year[0].total_tunggakan_last_year : 0
                          list.dpp = (list.arrears_students_non_rutin.find((i) => i.nick_name == "DPP") ? list.arrears_students_non_rutin.find((i) => i.nick_name == "DPP").total_tunggakan : 0)
                          list.upp = (list.arrears_students_non_rutin.find((i) => i.nick_name == "UPP") ? list.arrears_students_non_rutin.find((i) => i.nick_name == "UPP").total_tunggakan : 0)
                          list.upa = (list.arrears_students_non_rutin.find((i) => i.nick_name == "UPA") ? list.arrears_students_non_rutin.find((i) => i.nick_name == "UPA").total_tunggakan : 0)
                          list.spp = list.arrears_classes[0] ? list.arrears_classes[0].total_tunggakan_spp : 0
                          list.jmlh = Number(list.thnlalu) + Number(list.dpp) + Number(list.upp) + Number(list.upa) + Number(list.spp)

                          ttl_klsThnlalu = ttl_klsThnlalu + Number(list.thnlalu);
                          ttl_klsDPP = ttl_klsDPP + Number(list.dpp);
                          ttl_klsUPP = ttl_klsUPP + Number(list.upp);
                          ttl_klsUPA = ttl_klsUPA + Number(list.upa);
                          ttl_klsSPP = ttl_klsSPP + Number(list.spp);
                          ttl_kls = ttl_kls + list.jmlh;
                        })()}
                        <td key={`table_index_${idx}`} style={{textAlign:"center"}}>
                          {idx + 1}
                        </td>
                        <td style={{textAlign:"center"}}>{list.no_va}</td>
                        <td style={{textTransform: "capitalize"}}>{capitalize(list.name)}</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.thnlalu)}</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.dpp)}</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.upp)}</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.upa)}</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.spp)}</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.jmlh)}</td>
                      </tr>
                    ))}
                  </tbody>
                  <thead>
                    <tr>
                      <th colSpan={3}>Total</th>
                      <th style={{textAlign:"right"}}>{commaFormatted(ttl_klsThnlalu)}</th>
                      <th style={{textAlign:"right"}}>{commaFormatted(ttl_klsDPP)}</th>
                      <th style={{textAlign:"right"}}>{commaFormatted(ttl_klsUPP)}</th>
                      <th style={{textAlign:"right"}}>{commaFormatted(ttl_klsUPA)}</th>
                      <th style={{textAlign:"right"}}>{commaFormatted(ttl_klsSPP)}</th>
                      <th style={{textAlign:"right"}}>{commaFormatted(ttl_kls)}</th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          )}
          {(tunggakan=="akumulasi" && !isEmpty(tunggakanakumulasi)) && (
            <div className="cetak-buku-induk2__header-title3">
              <div className="cetak-buku-induk2__header-title3--left" style={{marginLeft:"unset"}}>
                {!isPrint && (
                  <>
                    <table className="table-report-header">
                      <tr>
                        <th rowSpan="2">
                          <img style={{width:"70px"}} src={logo} />
                        </th>
                        <th style={{ paddingRight: '10rem' }}>
                          <h2>{school_unit.name}</h2>
                        </th>
                      </tr>
                      <tr>
                        <th style={{ paddingRight: '10rem' }}>
                          <h3>Tunggakan Akumulasi</h3>
                        </th>
                      </tr>
                    </table>
                    <table className="laporan-ki4__table-test" style={{width:"unset"}}>
                      <tr>
                        <td>Tahun Ajaran </td>
                        <td>&emsp; : {period2+"/"+(Number(period2)+1)}</td>
                      </tr>
                    </table>
                  </>
                )}
                <br></br>
                <table className="tablespp">
                  <thead>
                    <tr>
                      <th rowSpan={2}>No</th>
                      <th rowSpan={2}>Kelas</th>
                      <th colSpan={6}>Tunggakan</th>
                    </tr>
                    <tr>
                      <th>Σ Tahun Lalu</th>
                      <th>DPP</th>
                      <th>UPP</th>
                      <th>UPA</th>
                      <th>SPP</th>
                      <th>Jumlah</th>
                    </tr>
                  </thead>
                  <tbody>
                    {map(tunggakanakumulasi, (list, idx) => (
                      <tr>
                        {(() => {
                          list.thnlalu = 0
                          list.dpp = 0;
                          list.upp = 0;
                          list.upa = 0;
                          list.spp = 0;

                          // map(list.ArrearsRutinLastYear, (listthnlalu, idxthnlalu) => {
                            list.thnlalu = list.thnlalu + Number(list.tunggakan_lastyear)
                          // })
                          // map(list.ArrearsNonRutin, (listnon, idxnon) => {
                            // switch(listnon.nick_name){
                            //   case "DPP":
                                // list.dpp = list.dpp + Number(listnon.total_tunggakan_non_rutin)
                              // break;
                              // case "UPP":
                                // list.upp = list.upp + Number(listnon.total_tunggakan_non_rutin)
                              // break;
                              // case "UPA":
                                // list.upa = list.upa + Number(listnon.total_tunggakan_non_rutin)
                              // break;
                            // }
                          // })
                          // map(list.ArrearsRutin, (listspp, idxspp) => {
                            list.spp = list.spp + Number(list.tunggakan)
                          // })

                          list.jmlh = Number(list.tunggakan_lastyear) + Number(list.tunggakan_dpp) + Number(list.tunggakan_upp) + Number(list.tunggakan_upa) + Number(list.tunggakan)

                          ttl_akmThnlalu+=Number(list.tunggakan_lastyear);
                          ttl_akmDPP+=Number(list.tunggakan_dpp);
                          ttl_akmUPP+=Number(list.tunggakan_upp);
                          ttl_akmUPA+=Number(list.tunggakan_upa);
                          ttl_akmSPP+=Number(list.tunggakan);
                          ttl_akm+=Number(list.jmlh);

                          console.log(list.jmlh)
                        })()}
                        <td key={`table_index_${idx}`} style={{textAlign:"center"}}>
                          {idx + 1}
                        </td>
                        <td>{list.name}</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.tunggakan_lastyear)}</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.tunggakan_dpp)}</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.tunggakan_upp)}</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.tunggakan_upa)}</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.tunggakan)}</td>
                        <td style={{textAlign:"right"}}>{commaFormatted(list.jmlh)}</td>
                      </tr>
                    ))}
                  </tbody>
                  <thead>
                    <tr>
                      <th colSpan={2}>Total</th>
                      <th style={{textAlign:"right"}}>{commaFormatted(ttl_akmThnlalu)}</th>
                      <th style={{textAlign:"right"}}>{commaFormatted(ttl_akmDPP)}</th>
                      <th style={{textAlign:"right"}}>{commaFormatted(ttl_akmUPP)}</th>
                      <th style={{textAlign:"right"}}>{commaFormatted(ttl_akmUPA)}</th>
                      <th style={{textAlign:"right"}}>{commaFormatted(ttl_akmSPP)}</th>
                      <th style={{textAlign:"right"}}>{commaFormatted(ttl_akm)}</th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          )}
        </form>

      </div>
    );
  }
}
Tunggakan.propTypes = {
  handleGetStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
Tunggakan.defaultProps = {
  handleGetStudent: noop,
};
