import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map } from "lodash";
import {
  commaFormatted,
  normalizeAmount,
  formatData,
} from "../../../../utils/transformer.util";
import {
  Select,
  Input,
  Button,
  Pagination,
  InputDate,
  SearchSelect,
} from "../../../../components/base";
import {
  RIWAYAT_TABLE_FIELD_LIST,
  RIWAYAT_OFFLINE_TABLE_FIELD_LIST,
  RIWAYAT_H2H_TABLE_FIELD_LIST,
} from "../../../../constants/finance/spp/create_invoice.constant";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ReactToPrint from "react-to-print";
import language from "../../../../languages";

const MySwal = withReactContent(Swal);

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class LaporanPembayaranTotal extends PureComponent {
  constructor(props) {
    super(props);

    this._onFormChange = this._onFormChange.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    // this._setForm = this._setForm.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    // this._reportTotalPayments = this._reportTotalPayments.bind(this);
    // this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);

    const { user } = this.props;
    const { school_unit } = user;
    const { id, levels_id } = school_unit;
    this.state = {
      expanded: null,
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      list: [],
      filters: {},
      form: {
        value: {
          classes: [],
          levels_id,
          periods: "",
          listhistory: [],
          classes_id: "",
          pg_code: "",
          payload: [],
        },
        error: {
          classes: "",
          levels: "",
          listhistory: "",
        },
      },
      param: {},
      filtered: false,
    };
    this.checkboxRefs = [];
  }

  handleChange = (panel) => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  componentDidMount() {
    const { user, location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, students_id, payments_type } = state;
    const { school_unit } = user;
    // const paramTypes = ["classes", "levels", "classrooms"];
    const { content } = this.state;
    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
    // this._reportTotalPayments();
    // this._getStudentSPPOptions({ units_id: [school_unit.id] });
    // if (isEdit) {
    //   this._setForm(students_id, payments_type);
    // }
  }

  _onFormChange(event) {
    const { name, value, dataset } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {}
    );
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => this._onSearchContent()

    );
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }


  async _reportTotalPayments(payload = {}) {
    const { filters } = this.state;
    const { handleReportTotalPayments, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;

    if (filters.date === "") {
        delete filters.date;
      }
      if (filters.date_to === "") {
        delete filters.date_to;
      }

    const res = await handleReportTotalPayments({
      ...payload,
      ...filters,
      va_code: school_unit.va_code,
    });

    this.setState((prevState) => ({
      ...prevState,
      total: res,
      content: {
        loading: false,
      },
    }));
  }

  async _onSearchContent(params = {}, payload = {}) {
    const { filters } = this.state;
    const { students_id } = filters;
    
    if (filters.date === "") {
      delete filters.date;
    }
    if (filters.date_to === "") {
      delete filters.date_to;
    }

    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const {
            handleReportTotalPayments,
            user
          } = this.props;
          const { organizations_id, school_unit } = user;
         const { units_id, levels_id } = school_unit;
          let res = "";


          // list h2h
        //   if (filters.payments_type === "h2h") {
            // res = handleHistoryDetailPaymentsH2H;
            // const result = await handleHistoryDetailPaymentsH2H({
            //   ...params,
            //   ...filters,
            //   temps_id: students_id.value,
            // });
            // this.setState({
            //   listH2h: result,
            // });
        //   }

          // untuk set state list payment gateway dan offline
        //   else {
            res = await handleReportTotalPayments({
              ...payload,
              ...filters,
              va_code: school_unit.va_code,
            });

            console.log(res)
            this.setState({
              total: res,
            });
        //   }
        } catch (err) {
          this.setState({
            total:'',
          });
        }
      }
    );
  }



  
  render() {
    const { param, form, content, filters, listH2h, total } = this.state;
    const { date = "", date_to = "", payments_type, students_id } = filters;
    console.log(total)
    const paymentstype = [
      { label: "Payment Gateway", value: "Faspay" },
      { label: "Offline", value: "Offline" },
      { label: "H2H", value: "H2H" },
    ];

    var count = 0;

    if (date > date_to) {
      form.error.date = "Format Tanggal Salah";
      form.error.date_to = "Format Tanggal Salah";
    } else {
      form.error.date = "";
      form.error.date_to = "";
    }

    console.log(payments_type)

    return (
      <div className="manage-registration">
        <div className="budget__title">
          <h1>Laporan Pembayaran</h1>
          <hr></hr>
        </div>

        <div className="absensi-rekap__custom-form">
            <div className="manage-registration__custom-form-row">
              <div className="manage-registration__custom-form-row__field">
                <Select
                  type="text"
                  name="payments_type"
                  label="Tipe Pembayaran"
                  placeholder="Pilihan"
                  data={paymentstype}
                  onChange={this._onChangeFilter}
                  value={payments_type}
                />
              </div>
              <div className="manage-registration__custom-form-row__field">
                <InputDate
                  type="date"
                  name="date"
                  label="Dari Tanggal"
                  onChange={this._onChangeFilter}
                  value={date}
                  error={form.error.date || ""}
                />
                <div className="manage-registration__custom-form-row-datadiri">
                  <InputDate
                    type="date"
                    name="date_to"
                    label="Sampai Tanggal"
                    onChange={this._onChangeFilter}
                    value={date_to}
                    error={form.error.date_to || ""}
                  />
                </div>
              </div>
            </div>
        </div>

        <br></br>
        
        <h1>Total Pembayaran {payments_type == 'Faspay'? 'Payment Gateway':payments_type} : Rp. {commaFormatted(total)}</h1>

        <br></br>
      </div>
    );
  }
}
LaporanPembayaranTotal.propTypes = {
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
LaporanPembayaranTotal.defaultProps = {
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
