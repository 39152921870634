import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map, isEqual, has, find, capitalize } from "lodash";
import StudentReportFilter from "../../../components/Filter/StudentReport.filter.component";
import ListForm from "../../../components/ListForm/ListForm.component";
import ContentTable from "../../../components/ContentTable/ContentTable.component";
import { ABSENSI_HARIAN } from "../../../constants/student.constant";
import { ABSENSI_HARIAN_TABLE } from "../../../constants/student.constant";
import { validateRequiredFields } from "../../../utils/validation.util";
import {
  commaFormatted,
  normalizeAmount,
  formatData,
} from "../../../utils/transformer.util";
import {
  Button,
  Input,
  Checkbox,
  Pagination,
  Select,
  InputDate
} from "../../../components/base/index";
import AbsensiHarianForm from "../components/AbsensiHarianForm.component";
import AbsensiHarianPage1 from "../components/AbsensiHarianPage1.component";
import AbsensiHarianPage2 from "../components/AbsensiHarianPage2.component";
import TabelAbsensiHarian from "../components/TabelAbsensiHarian.component";
import { ATTENDANCE_LIST_TABLE_FIELDS } from "../../../constants/kurikulum/kurikulum.constant";
import language from "../../../languages";
import { errorAlert } from '../../../utils/alert.util';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class AbsensiHarian extends Component {
  constructor(props) {
    super(props);
    this._onClickUpload = this._onClickUpload.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    // this.isMount = false;
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onClickNext = this._onClickNext.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this.handleGetData = this.handleGetData.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this._onCheckDetail = this._onCheckDetail.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onChangeListAmount = this._onChangeListAmount.bind(this);
    this._setForm = this._setForm.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    // this._getPrmLevels = this._getPrmLevels.bind(this);

    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    this.state = {
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          levels_id,
          organizations_id: 3,
          absensiharian: [
            {
              students_id: "",
              period: "",
              classrooms_id: "",
              date: "",
              status: "",
              description: "",
            },
          ],
        },
        error: {
          classes: "",
          levels: "",
          absensiharian: "",
        },
      },
      param: {},
    };
    this._handleFormChange = this._handleFormChange.bind(this);
    this._onSearch = this._onSearch.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
    // this._getPrmLevels({ filters: {units_id: [school_unit.id], organizations_id: 3 } });

  }

  componentDidMount() {
    const { location, getLevels, getClasses, user } = this.props;
    const { state = {} } = location;
    const { school_unit } = user;
    const { isEdit = false, data, date, period, classrooms_id } = state;
    const { id } = state.data || "";
    const { content } = this.state;
    const paramTypes = ["classes", "levels", "classrooms"];

    console.log(state)
    paramTypes.forEach((type) => {
      // this._getParamOptions(type);
      this._getParamOptionsAcademics(type);
    });
    if (isEdit) {
      // this.setState({ id: id });
      this._setForm(date, period, classrooms_id);
    }
    // this.handleGetData();
    // this.isMount = true;
    else if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
    this._getPeriodsOptions();
    // this._getPrmLevels({ filters: {units_id: [school_unit.id], organizations_id: 3 } });

  }

  // async _getPrmLevels(filters = {}) {
  //   const { getPrmLevels, user } = this.props;
  //   const { organizations_id, school_unit } = user;
  //   const { units_id, levels_id } = school_unit;
  //   const res = await getPrmLevels(filters);
  //   filters.organizations_id = 3;

  //   this.setState((prevState) => ({
  //     ...prevState,
  //     param: {
  //       ...prevState.param,
  //       levels: res,
  //     },
  //   }));
  // }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    //  console.log(state)
    if ((!isEdit || !id) && !isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  async _setForm(date, period, classrooms_id) {
    const { handleGetAttendance } = this.props;
    try {
      const payload = await handleGetAttendance({ 
        date,
        period,
        classrooms_id,
       });

      // console.log(payload)
      this.setState({
        filters: {
          classrooms_id: payload.classrooms_id,
          period: payload.period,
          date: payload.date,
        },
        form: {
          value: {
            date: payload.date,
            period: payload.period,
            organizations_id: payload.organizations_id,
            absensiharian: map(payload.absensiharian, (detail) => ({
              classrooms_id: detail.classrooms_id,
              name: detail.name,
              date: detail.date,
              organizations_id: detail.organizations_id,
              id: detail.id,
              status: detail.status,
              description: detail.description,
            })),
          },
          error: {
            absensiharian: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  async _onSearchContent(params = {}) {
    const { filters } = this.state;

    try {
      const { handleListStudentAttendance, user } = this.props;
      const { school_unit, workingUnit, id, user_group, organizations_id } =
        user;
      const { levels_id, units_id } = school_unit;
      const result = await handleListStudentAttendance({
        ...params,
        organizations_id: 3,
        units_id: school_unit.id,
        filters,
      });
      // if (this.isMount) {
      if (result) {
        result.forEach((item) => {
          if (!item.description) {
            item.description = "";
          }
          if (!item.status) {
            item.status = "HADIR";
          }
        });
      }
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            absensiharian: result,
          },
        },
      }));

      // }
    } catch (err) {
      // if (this.isMount) {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            absensiharian: [],
          },
        },
      }));
    }
    // }
  }

  _onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }

  async _getPeriodsOptions(filters = {}) {
    const { getPeriodsOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPeriodsOptions(filters);
    filters.organizations_id = 3;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async onEdit() {
    const { history, handleEditAttendance } = this.props;
    const { form } = this.state;
    const { value } = form;
    const res = await handleEditAttendance(value);
    history.push("/dashboard/kesiswaan/absensi-siswa/absensi-rekap");
  }

  _onChangeFilter(e) {
    const { user } = this.props;
    const { organizations_id } = user;
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        if (name === "levels_id") {
          this._getParamOptionsAcademics("classrooms", {
            filters: { levels_id: value, organizations_id: 3 },
          });
        }
        this._onSearchContent();
      }
    );
  }
  _onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    // this.handleGetData({
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  async handleGetData(payload = {}) {
    const { handleListStudentAttendance, handleListAnnouncementStudent, user } =
      this.props;
    const { units_id, workingUnit, id, user_group, organizations_id } = user;
    let unit_id = units_id;

    if (unit_id === null && workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }
    console.log(user);
    // if(user_group.id === 2){
    const res = await handleListStudentAttendance({
      ...payload,
      user_id: id,
      organizations_id: 3,
    });

    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          absensiharian: res,
        },
      },
    }));
  }
  _handleFormChange(event) {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          [name]: value,
        },
        error: {
          ...prevState.form.error,
          [name]: "",
        },
      },
    }));
  }

  _onSearch() {
    const { form } = this.state;
    const error = validateRequiredFields(form.value, ["year"]);
    this.setState((prevState) => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (isEmpty(error)) {
      this.setState({ filtered: true });
    }
  }

  _onFormChange(event) {
    const { user } = this.props;
    const { organizations_id } = user;
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked ? value : "";
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName] || [];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        // if (name === 'levels_id') {
        //   this._getClassesOptions({ filters: { levels_id: [value] }} );
        // }
        if (name === "levels_id") {
          this._getParamOptionsAcademics("classrooms", {
            levels_id: value,
            organizations_id: 3,
          });
        }
        // if (name === 'classes_id') {
        //   this._getParamOptions( 'classes', { filters: { levels_id: value, organizations_id: 3 }} );
        // }
        // if (name === 'classes') {
        //   this._getStudentOptions({ filters: { classes_id: value.map(item => item.value)}} );
        // }
      }
    );
  }
  _onClickUpload() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/absensi-siswa/upload-absensi-harian");
  }

  _onClickAdd(data) {
    const { form } = this.state;
    const { plafon } = form.value;

    plafon.push({
      tipePlafon: "",
      // divisi: '',
      nominal: 0,
      //period:'',
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          plafon,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
    // console.log(plafon)
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.handleGetData({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _onCheckDetail(event) {
    const { target } = event;
    const { value, checked } = target;
    const { budgets, selectedBudgetDetails } = this.props;
    const { selected, unchecked } = this.state;
    let newState = { ...this.state };

    newState = this._setNewState(newState, checked, value);

    this.setState({
      ...newState,
    });
  }

  async onSubmit() {
    const { history, handleSaveAttendance } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    const dataForm = form.value;
    if(form.value.date && form.value.date != ""){
      canSaveA = true;
    } else {
      canSaveA = false;
    }
    if (canSaveA == false){
      errorAlert({
        title: 'Harap lengkapi Tanggal ',
      });
    } else {
      await handleSaveAttendance(value);
      history.push("/dashboard/kesiswaan/absensi-siswa/absensi-rekap");
    }
  }

  _onClickNext() {
    window.scrollTo(0, 0);
    this.setState((prevState) => ({
      page: prevState.page + 1,
    }));
  }
  async _getParamOptions(type, filters = {}) {
    const { getParamOptions, user } = this.props;
    const { organizations_id } = user;

    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = 3;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit } = user;
    // const { levels_id } = school_unit;

    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = 3;
      filters.units_id = school_unit.id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  render() {
    const {
      param,
      form,
      page,
      list = {},
      selected,
      checkedAll,
      content,
      filters,
      listAmount,
    } = this.state;
    const { absensiharian } = form.value;
    const {
      classrooms_id = "",
      date = "",
      period = "",
      levels_id = "",
    } = filters;
    const { location } = this.props;
    const loadingProps = { show: content.loading };
    const academic_year = [
      { label: "2019", value: "2019" },
      { label: "2020", value: "2020" },
      { label: "2021", value: "2021" },
    ];
    let checked = {};

    var thisyear = new Date().getFullYear();
    var month = new Date().getMonth() + 1;
    if (month < 7) {
      thisyear -= 1;
    }
    map(param.periods, (data, idx) => {
      var year = data.label.substring(0, 4);
      if (year == thisyear) {
        form.value.period = data.value;
      }
    });

    return (
      <div className="manage-registration">
        <div className="budget__title">
          <h1>Isi Absensi Harian</h1>
        </div>
        <div className="kode-matpel__custom-form">
          <div className="kode-matpel__custom-form-row">
            <div className="kode-matpel__custom-form-row__field">
              <Select
                type="text"
                name="levels_id"
                disabled
                label="Unit"
                placeholder="Pilih Unit"
                data={param.levels}
                value={form.value.levels_id}
                onChange={this._onFormChange}
              />
            </div>

            <div className="kode-matpel__custom-form-column__field">
              <Select
                placeholder="Pilih Kelas"
                label="Kelas"
                name="classrooms_id"
                value={classrooms_id}
                data={param.classrooms}
                onChange={this._onChangeFilter}
              />
            </div>
          </div>
          <div className="kode-matpel__custom-form-row">
            <div className="kode-matpel__custom-form-row__field">
              <InputDate
                type="date"
                name="date"
                label="Tanggal"
                onChange={this._onFormChange}
                value={form.value.date}
                error={form.error.date || ""}
              />
            </div>

            <div className="kode-matpel__custom-form-column__field">
              <Select
                label="Tahun Ajaran"
                name="period"
                onChange={this._onFormChange}
                placeholder="Pilihan"
                data={param.periods}
                value={form.value.period}
                error={form.error.period || ""}
                disabled
              />
            </div>
          </div>
        </div>
        {/* <AbsensiHarianPage1
                    form={form}
                    onFormChange={this._onFormChange}
                    param={param}
                  /> */}

        {/* <TabelAbsensiHarian
            list={absensiharian}
            onAddList={this._onClickAdd}
            onFormChange={this._onFormChange}
            onDeleteList={this._onDeleteList}
          /> */}

        <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {map(ATTENDANCE_LIST_TABLE_FIELDS.label, (field, idx) => (
                  <th key={`table_th_${idx}`}>{field}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {map(absensiharian, (list, idx) => (
                <tr key={`budget_row_${idx}`}>
                  {map(
                    ATTENDANCE_LIST_TABLE_FIELDS.value,
                    (field, fieldIdx) => {
                      if (fieldIdx === 0) {
                        return (
                          <td style={{textAlign:'center'}}>{idx+1}</td>
                        );
                      }
                      if (field.attribute === "nisn"){
                        return (
                          <td style={{textAlign:'center'}}>{list.no_va}</td>
                        );
                      }
                      if (field.attribute === "no_va"){
                        return (
                          <td style={{textAlign:'center'}}>{list.no_va}</td>
                        );
                      }
                      if (field.attribute === 'name'){
                        return(
                        <td style={{textTransform: "capitalize"}}>{capitalize(list.name)}</td>
                        )
                      }
                      // if (field.attribute === 'status2') {
                      //   return (
                      //     <td key={`table_${fieldIdx}_${idx}`}>
                      //     <Input
                      //     type="checkbox"
                      //     name="status2"
                      //     data-input-array={true}
                      //     data-array-position={idx}
                      //     data-field-name='absensiharian'
                      //     onChange={this._onFormChange}
                      //     defaultChecked={list.status2 === field.value}
                      //     value={field.value}
                      //     isChecked= 'true'
                      //   />  </td>
                      //   );
                      // }
                      // console.log("STATUS " + list.status)
                      if (field.attribute === "status") {
                        return (
                          <td>
                            <Input
                              type="checkbox"
                              style={{alignItems:"center",  justifyContent: "center", display: "flex"}}
                              name="status"
                              data-input-array={true}
                              data-array-position={idx}
                              data-field-name="absensiharian"
                              onChange={this._onFormChange}
                              //  checked={list.status === 'HADIR'}
                              checked={
                                !field.value || field.value === "HADIR"
                                  ? list.status === "HADIR"
                                  : list.status === field.value
                              }
                              value={field.value}
                            />{" "}
                          </td>
                        );
                      }
                      if (field.attribute === "description") {
                        return (
                          <td key={`table_${fieldIdx}_${idx}`}>
                            <Input
                              type="text"
                              name="description"
                              data-input-array={true}
                              data-array-position={idx}
                              data-field-name="absensiharian"
                              onChange={this._onFormChange}
                              value={list.description}
                            />{" "}
                          </td>
                          // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                        );
                      }

                      return (
                        <td
                          className={field.type === "number" ? "nominal" : ""}
                          key={`table_${fieldIdx}_${idx}`}
                        >
                          {formatData(list, field)}
                        </td>
                      );
                    }
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="student-list__header">
          <div className="student-list__button">
            {this.state.id ? (
              <Button type="button" title="Update" onClick={this.onEdit} />
            ) : (
              <Button type="button" title="Simpan" onClick={this.onSubmit} />
            )}
          </div>
        </div>
      </div>
    );
  }
}
AbsensiHarian.propTypes = {
  getParamOptions: PropTypes.func,
  history: PropTypes.object.isRequired,
  handleGetAttendance: PropTypes.func,
  // handleGetLevels: PropTypes.func,
};
AbsensiHarian.defaultProps = {
  getParamOptions: noop,
  handleGetAttendance: noop,
  // handleGetLevels: noop,
};
