import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, isEqual, map, capitalize } from "lodash";
import StudentReportFilter from "../../../components/Filter/StudentReport.filter.component";
import ListForm from "../../../components/ListForm/ListForm.component";
import ContentTable from "../../../components/ContentTable/ContentTable.component";
import { LAPORAN_ABSEN } from "../../../constants/student.constant";
import { ABSENSI_REKAP_TABLE } from "../../../constants/student.constant";
import { validateRequiredFields } from "../../../utils/validation.util";
import {
  commaFormatted,
  normalizeAmount,
  formatData,
} from "../../../utils/transformer.util";
import {
  Button,
  Select,
  Pagination,
  SearchSelect,
  Input,
} from "../../../components/base/index";
import LaporanAbsensiTable from "../components/LaporanAbsensiTable.component";
import { STUDENT_TABLE_UNIT_FIELDS } from "../../../constants/student.constant";
import language from "../../../languages";
import ReactToPrint from "react-to-print";
import { Link } from "react-router-dom";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class DaftarSiswaKls extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onClickNext = this._onClickNext.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    //this._onAddList = this._onAddList.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._getClassesOptions = this._getClassesOptions.bind(this);
    this._handleFormChange = this._handleFormChange.bind(this);
    this._onSearch = this._onSearch.bind(this);
    this._studentCount = this._studentCount.bind(this);
    this._onChangePage = this._onChangePage.bind(this);
    this.onClickCetak = this.onClickCetak.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this._onClickExcel = this._onClickExcel.bind(this);
    // this._getPrmLevels = this._getPrmLevels.bind(this);
    this._initializeData = this._initializeData.bind(this);

    const { user } = this.props;
    const { school_unit } = user;
    const { units_id, levels_id } = school_unit;
    this.state = {
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          total_siswa: "",
          classes: [],
          levels_id,
          listsiswa: [],
          classes_id: "",
          user_id:user.id
        },
        error: {
          classes: "",
          levels: "",
          listsiswa: "",
        },
      },
      param: {},
      filtered: false,
      total_siswa: "",
    };
  }

  componentDidMount() {
    const {
      handleGetCodeOfAccount,
      location,
      handleGetClasses,
      handleGetLevels,
      user,
    } = this.props;
    const { school_unit, organizations_id } = user;
    const { levels_id, units_id } = school_unit;
    const { content, filters, form } = this.state;

    let savedFilters = JSON.parse(window.localStorage.getItem('changeFilters')) || filters;
    let savedForm = JSON.parse(window.localStorage.getItem('changeForm')) || "";
    if (savedForm != "" && user.id != savedForm.value.user_id) {
      savedForm = "";
      savedFilters = filters;
    }
    this._initializeData(savedFilters);
    if(savedForm != "") {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            // levels_id: savedForm.value.levels_id,
            classes_id: savedForm.value.classes_id,
            update: true,
          },
        },
      }));
      // this._getClassesOptions({ filters: { levels_id: savedForm.value.levels_id } });
      this._getParamOptionsAcademics("classrooms", {
        classes_id: savedForm.value.classes_id,
        organizations_id,
      });
    }

    // if (isEmpty(content.list)) {
    //   this._onSearchContent({ page: 1 });
    // }
  }

  async _initializeData(savedFilters = {}) {
    const { user } = this.props
    const { school_unit } = user;
    const { levels_id, units_id } = school_unit;
    
    const paramTypes = ["classes","levels","classrooms"];
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    
    this._getPeriodsOptions({units_id: school_unit.id});
    this._studentCount();
    // this._getPrmLevels({ filters: {units_id: [school_unit.id], organizations_id: 3 } });
    this._getClassesOptions({ filters: { levels_id: [levels_id], units_id: [school_unit.id] } });

    this.setState({filters: savedFilters}, () => { 
      // this._onSearchContent() 
    });
  }

  componentDidUpdate(prevProps) {
    // const { user } = this.props;
    // const { workingUnit } = user;
    // const { user: prevUser } = prevProps;
    // const { workingUnit: prevWorkingUnit } = prevUser;

    // if (!isEqual(workingUnit, prevWorkingUnit)) {
    //   this.handleGetData();
    // }
    // if (!isEqual(prevProps, this.props)) {
    //   this._onSearchContent({ page: 1 });
    // }
  }

  onClickCetak() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/laporan/daftar-siswa-kelas-unit", {
      isEdit: true,
    });
  }

  _onClickExcel() {
    const { history } = this.props;
    const { form, filters } = this.state;  
    history.push("/dashboard/kesiswaan/laporan/daftar-siswa-aktif/cetak-excel", {
      isEdit: true,
      data: {
        classes_id: form.value.classes_id,
        classrooms_id: filters.classrooms_id,
      }
    });
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { school_unit } = user;
    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = 3;
      filters.units_id = school_unit.id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }
  // on formchange untuk form
  _handleFormChange(event) {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          [name]: value,
        },
        error: {
          ...prevState.form.error,
          [name]: "",
        },
      },
    }));
  }

  _onSearch() {
    const { form } = this.state;
    const error = validateRequiredFields(form.value, ["year"]);
    this.setState((prevState) => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (isEmpty(error)) {
      this.setState({ filtered: true });
    }
  }

  async _getParamOptions(type, filters = {}) {
    const { getParamOptions, user } = this.props;
    const { organizations_id } = user;
    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = 3;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getClassesOptions(filters = {}) {
    const { getClassesOptions, user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    filters.levels_id = levels_id;
    filters.units_id = school_unit.id;

    const res = await getClassesOptions(filters);
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        classes_prm: res,
      },
    }));
  }

  // async _getPrmLevels(filters = {}) {
  //   const { getPrmLevels, user } = this.props;
  //   const { organizations_id, school_unit } = user;
  //   const { units_id, levels_id } = school_unit;
  //   const res = await getPrmLevels(filters);
  //   filters.organizations_id = 3;

  //   this.setState((prevState) => ({
  //     ...prevState,
  //     param: {
  //       ...prevState.param,
  //       levels: res,
  //     },
  //   }));
  // }

  async _getPeriodsOptions(filters = {}) {
    const { getPeriodsOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPeriodsOptions(filters);
    filters.organizations_id = 3;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async _studentCount(payload = {}) {
    const { filters } = this.state;
    const { handleStudentCount, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await handleStudentCount({
      ...payload,
      organizations_id: 3,
      units_id: school_unit.id,
    });
    // filters.organizations_id = organizations_id;
    // filters.units_id = units_id;

    this.setState((prevState) => ({
      ...prevState,
      total_siswa: res,
    }));
  }

  _onFormChange(event) {
    const { user } = this.props;
    const { organizations_id } = user;
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (name === "classes_id") {
          this.setState((prevState) => ({
            filters: {
              ...prevState.filters,
              classrooms_id: "",
            }
          }));
        }

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked;
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "levels_id") {
          this._getClassesOptions({ filters: { levels_id: [value] } });
        }
        if (name === "classes_id") {
          this._getParamOptionsAcademics("classrooms", {
            classes_id: value,
            organizations_id: 3,
          });
        }
        this._onSearchContent();
      }
    );
  }

  _onClickAdd(data) {
    const { form } = this.state;
    const { plafon } = form.value;

    plafon.push({
      tipePlafon: "",
      // divisi: '',
      nominal: 0,
      //period:'',
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          plafon,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }

  async _onSearchContent(params = {}) {
    const { filters, form } = this.state;
    const { value } = form;
    const { subject_id, classrooms_id, period } = value;
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const {
            handleListStudentClass,
            handleListReportStudent,
            handleListAchievement,
            user,
          } = this.props;
          const { school_unit, workingUnit, id, user_group, organizations_id } =
            user;
          const { levels_id, units_id } = school_unit;
          const result = await handleListStudentClass({
            ...params,
            organizations_id: 3,
            units_id: school_unit.id,
            // classrooms_id: classrooms_id,
            // period: period,
            filters,
          });
          // }
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listsiswa: result,
              },
            },
          }));
        } catch (err) {
          // if (this.isMount) {
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listsiswa: [],
              },
            },
          }));
        }
        // }
      }
    );
  }

  // on formchange untuk tabel
  onFormChange(event) {
    const { name, value, dataset } = event.target;
    const {
      inputArray = false,
      arrayPosition = 0,
      inputType = "text",
    } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      let newPlafon = [];
      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }

      if (inputArray) {
        newPlafon = prevState.form.value.plafon;
        newPlafon[arrayPosition][name] = value;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { plafon: newPlafon }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            form: "",
            [name]: "",
          },
        },
      };
    });
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        if (name == 'period'){
          this._studentCount({filters: {period: value}})
        }
        window.localStorage.setItem('changeFilters', JSON.stringify(this.state.filters));
        window.localStorage.setItem('changeForm', JSON.stringify(this.state.form));
        this._onSearchContent();
      }
    );
  }
  _onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    // this.handleGetData({
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _onClickNext() {
    window.scrollTo(0, 0);
    this.setState((prevState) => ({
      page: prevState.page + 1,
    }));
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/pendaftaran", {
      data: val,
      isEdit: true,
    });
  }

  _renderButtons(data) {
    const { user } = this.props;
    const { user_group } = user;
    const button = {};

    return (
      <td>
        <div className="table__actions">
          <Button onClick={() => this.onClickEdit(data)} title="Edit" />
        </div>
      </td>
    );
  }

  render() {
    const {
      param,
      form,
      page,
      list = {},
      selected,
      checkedAll,
      content,
      filters,
      listAmount,
      total_siswa,
    } = this.state;
    const { listsiswa } = form.value;
    const { classrooms_id = "", status_id = "", period = "" } = filters;
    // console.log(total_siswa);
    const { classes = {}, levels = {}, user } = this.props;
    const { school_unit } = user;
    const presence_status = [
      { label: "Mengikuti", value: "mengikuti" },
      { label: "Tidak Mengikuti", value: "tidak" },
    ];
    const status = [
      { label: "Hadir", value: "HADIR" },
      { label: "Sakit", value: "SAKIT" },
      { label: "Izin", value: "IZIN" },
      { label: "Alpha", value: "ALPHA" },
    ];
    const academic_year = [
      { label: "2019", value: "2019" },
      { label: "2020", value: "2020" },
      { label: "2021", value: "2021" },
    ];
    var year = new Date().getFullYear();
    var month = new Date().getMonth() + 1;
    if (month < 7) {
      year -= 1;
    }
    const componentRef = React.createRef();
    return (
      <div className="manage-registration">
        <h1>Daftar Siswa</h1>
        <div className="absensi-rekap__custom-form">
          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
              <Select
                name="period"
                label="Tahun Ajaran"
                onChange={this._onChangeFilter}
                data={param.periods}
                placeholder={"Pilihan"}
                value={period}
              />
               
            </div>
            <div className="absensi-rekap__custom-form-column__field">
            <Select
                type="text"
                name="levels_id"
                label="Unit"
                placeholder="Pilih Unit"
                disabled
                data={param.levels}
                onChange={this._onFormChange}
                value={form.value.levels_id}
              />
              
            </div>
          </div>

          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
            <Select
                  type="text"
                  name="classes_id"
                  label="Tingkat Kelas"
                  placeholder="Pilih Kelas"
                  data={param.classes_prm}
                  onChange={this._onFormChange}
                  value={form.value.classes_id}
                  error={form.error.classes_id || ""}
                />
           
            </div>
            <div className="absensi-rekap__custom-form-column__field">
            <Select
                type="text"
                name="classrooms_id"
                label="Kelas"
                placeholder="Pilih Kelas"
                data={param.classrooms}
                onChange={this._onChangeFilter}
                value={classrooms_id}
              />
            </div>
          </div>
          {/* <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">
              <Select
                  name="order_no"
                  label="Nomor Urut"
                  onChange={this._onFormChange}
                //   data={ classes.list}
                  placeholder={'Pilihan'}
                  value={form.value.order_no}
                />
              </div>
              <div className="absensi-rekap__custom-form-column__field">
                <Select
                    name="show_nisn"
                    label="Wali Kelas"
                    onChange={this._onFormChange}
                  //   data={ classes.list}
                    placeholder={'Pilihan'}
                    value={form.value.homeroom_teacher}
                  />
              </div>
          </div>
          <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">
              <Select
                  name="list_students"
                  label="List Siswa"
                  onChange={this._onFormChange}
                //   data={ classes.list}
                  placeholder={'Pilihan'}
                  value={form.value.list_students}  
                />
              </div>
              <div className="absensi-rekap__custom-form-column__field">
                
              <Select
                  name="siswa_exit"
                  label="Siswa yang Keluar"
                  onChange={this._onFormChange}
                //   data={ classes.list}
                  placeholder={'Pilihan'}
                  value={form.value.siswa_exit}
                  error={form.error.siswa_exit || ''}
                />
              </div>
          </div> */}
        </div>
        <div className="beginning-balance__button-wrapper">
          <ReactToPrint
            trigger={() => (
                <Button title="Cetak" />
            )}
            content={() => componentRef.current}
          />
          <Button
              title="Ekspor Excel"
              onClick={this._onClickExcel}
            />
          </div>
        <div className="manage-registration">
          <h1>Total Siswa {school_unit.name} : {total_siswa}</h1>
          <div className="fund-request__content">
            <table className="table" ref={componentRef}>
              <thead>
                <tr>
                  {map(STUDENT_TABLE_UNIT_FIELDS.label, (field, idx) => (
                    <th key={`table_th_${idx}`}>{field}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {map(listsiswa, (list, idx) => (
                  <tr key={`budget_row_${idx}`}>
                    {map(STUDENT_TABLE_UNIT_FIELDS.value, (field, fieldIdx) => {
                      if (fieldIdx === 0) {
                        return (
                          <td style={{textAlign:'center'}}key={`table_index_${fieldIdx}_${idx}`}>
                            {idx + 1}
                          </td>
                        );
                      }
                      if (field.attribute === "nisn"){
                        return (
                          <td style={{textAlign:'center'}}>{list.nisn}</td>
                        );
                      }
                      if (field.attribute === "no_va"){
                        return (
                          <td style={{textAlign:'center'}}>{list.no_va}</td>
                        );
                      }
                      if (field.attribute === "students_name") {
                        return (
                          <td key={`table_${fieldIdx}_${idx}`}>
                            <Link
                              to={{
                                pathname:
                                  "/dashboard/kesiswaan/laporan/cetak-buku-induk",
                                state: { ...list },
                              }}
                            >
                              {list.students_name}
                            </Link>
                          </td>
                          // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                        );
                      }
                      return (
                        <td
                          className={field.type === "number" ? "nominal" : ""}
                          key={`table_${fieldIdx}_${idx}`}
                        >
                          {formatData(list, field)}
                        </td>
                      );
                    })}
                    {this._renderButtons(list)}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
DaftarSiswaKls.propTypes = {
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
DaftarSiswaKls.defaultProps = {
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
