import { connect } from 'react-redux';
import LaporanSPP from '../../../../views/Finance/Spp/LaporanSPP/LaporanSPP.view';
import { reportPaymentsRutin, reportPaymentsNonRutin, reportPaymentsNonRutinNew } from '../../../../states/thunks/academics.thunk';
import { getPrmLevels, getParamOptionsAcademics, getYearsOfEducationsOptions, getPrmPaymentsNon, getPeriodsOptions, getSchoolUnitsOptions } from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    user: state.user || {},
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleReportPaymentsRutin: (payload, goback) => dispatch(reportPaymentsRutin(payload, goback)),
    handleReportPaymentsNonRutin: (payload, goback) => dispatch(reportPaymentsNonRutin(payload, goback)),
    handleReportPaymentsNonRutinNew: (payload, goback) => dispatch(reportPaymentsNonRutinNew(payload, goback)),
    getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    getYearsOfEducationsOptions: (payload) => dispatch(getYearsOfEducationsOptions(payload)),
    getPrmPaymentsNon: (payload) => dispatch(getPrmPaymentsNon(payload)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    getSchoolUnitsOptions: (payload) => dispatch(getSchoolUnitsOptions(payload)),

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LaporanSPP);
