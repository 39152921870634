import { connect } from 'react-redux';
import PengaturanKlsAjaranBaru from '../../../views/Akademi/DataKelas/PengaturanKlsAjaranBaru.view';
import { listPPDBApprove, approveClassDivOffline, approveClassDivPPDB, deletePpdbSetting, listApproveNextClass, approveNextClass, listApproveGraduate, approveGraduations, cancelGraduate, deleteNextClass } from '../../../states/thunks/academics.thunk'
import { getPeriodsOptions, getPrmLevels, getParamOptionsAcademics,  } from '../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
    classes: state.options.classes,
    levels: state.options.levels,
    result: state.result,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleListPPDBApprove: (payload, goback) => dispatch(listPPDBApprove(payload, goback)),
    handleListApproveNextClass: (payload, goback) => dispatch(listApproveNextClass(payload, goback)),
    handleApproveClassDivOffline: (payload, goback) => dispatch(approveClassDivOffline(payload, goback)),
    handleApproveClassDivPPDB: (payload, goback) => dispatch(approveClassDivPPDB(payload, goback)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handledeletePpdbSetting: (payload) => dispatch(deletePpdbSetting(payload)),
    handleApproveNextClass: (payload, goback) => dispatch(approveNextClass(payload, goback)),
    handleListApproveGraduate: (payload, goback) => dispatch(listApproveGraduate(payload, goback)),
    handleApproveGraduations: (payload, goback) => dispatch(approveGraduations(payload, goback)),
    handleCancelGraduate: (payload, goback) => dispatch(cancelGraduate(payload, goback)),
    handleDeleteNextClass: (payload, goback) => dispatch(deleteNextClass(payload, goback)),
    
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PengaturanKlsAjaranBaru);