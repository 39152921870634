import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budgeting from '../../../assets/img/finance/anggaran.png';
import bookkeeping from '../../../assets/img/bookkeeping/jurnal_bank.png';
import catatan_anekdot from '../../../assets/img/akademi/kurikulum/Ulangan/catatan-anekdot.png';
import daftar_nilai from '../../../assets/img/akademi/kurikulum/Ulangan/daftar-nilai.png';
import pengisian_aspek_perkembangan from '../../../assets/img/akademi/kurikulum/Ulangan/pengisian-aspek-perkembangan.png';
import pengisian_indikator_sikap from '../../../assets/img/akademi/kurikulum/Ulangan/pengisian-indikator-sikap.png';
import pengisian_nilai_kognitif from '../../../assets/img/akademi/kurikulum/Ulangan/pengisian-nilai-kognitif.png';
import pengisian_observasi from '../../../assets/img/akademi/kurikulum/Ulangan/pengisian-observasi.png';

export default class Ulangan extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickDaftarNilai = this.onClickDaftarNilai.bind(this);
    this.onClickNilaiKognitif = this.onClickNilaiKognitif.bind(this);
    this.onClickPsik = this.onClickPsik.bind(this);
    this.onClickHarian = this.onClickHarian.bind(this);
    this.onClickSikap = this.onClickSikap.bind(this);
    this.onClickPengisianKompetensiDasar = this.onClickPengisianKompetensiDasar.bind(this);
    this.onClickCatatanAnekdot = this.onClickCatatanAnekdot.bind(this);
  }

  onClickDaftarNilai() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/daftar-nilai');
  }

  onClickNilaiKognitif() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/pengisian-nilai-kognitif');
  }

  onClickPsik() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/pengisian-psik-afek');
  }

  onClickPengisianKompetensiDasar() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/pengisian-kompetensi-dasar');
  }

  onClickCatatanAnekdot() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/catatan-anekdot');
  }

  onClickHarian() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/pengisian-observasi-harian');
  }

  onClickSikap() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/pengisian-indikator-sikap');
  }


  render() {
    return (
      <div className="finance">
        {/* <div className="finance__list">
          <button onClick={this.onClickSikap}>
            <img src={pengisian_indikator_sikap} alt="psikafek" />
            <h3>Pengisian Indikator Sikap</h3>
          </button>
        </div> */}
        <div className="finance__list">
          <button onClick={this.onClickNilaiKognitif}>
            <img src={pengisian_nilai_kognitif} alt="NilaiKognitif" />
            <h3>Pengisian Nilai</h3>
          </button>
        </div>
        {/* <div className="finance__list">
          <button onClick={this.onClickHarian}>
            <img src={pengisian_observasi} alt="observasiharian" />
            <h3>Pengisian Observasi & Daftar Nilai</h3>
          </button>
        </div> */}
        <div className="finance__list">
          <button onClick={this.onClickDaftarNilai}>
            <img src={daftar_nilai} alt="DaftarNilai" />
            <h3>Daftar Nilai</h3>
          </button>
        </div>
        {/* <div className="finance__list">
          <button onClick={this.onClickPengisianKompetensiDasar}>
            <img src={pengisian_aspek_perkembangan} alt="Pengisiankompetensidasar" />
            <h3>Pengisian Aspek Perkembangan & Kompetensi Dasar</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickCatatanAnekdot}>
            <img src={catatan_anekdot} alt="CatatanAnekdot" />
            <h3>Catatan Anekdot</h3>
          </button>
        </div> */}
      
      </div>
    );
  }
}
Ulangan.propTypes = {
  history: PropTypes.object.isRequired,
};
