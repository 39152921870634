import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map } from "lodash";
import {
  normalizeAmount,
} from "../../../../utils/transformer.util";
import { Button, Select, Input, SelectMultiple } from "../../../../components/base/index";
import { validateRequiredFields } from '../../../../utils/validation.util';
import { errorAlert } from '../../../../utils/alert.util';

export default class CreateInvoiceKelas extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onRemoveMulti = this._onRemoveMulti.bind(this);
    this._onChangeMulti = this._onChangeMulti.bind(this);
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getYearsOfEducationsOptions = this.getYearsOfEducationsOptions.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    // this._getPrmLevels = this._getPrmLevels.bind(this);
    this._getStudentSPPOptions = this._getStudentSPPOptions.bind(this);
    this.getPrmPayments = this.getPrmPayments.bind(this);

    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id, va_code, unit_code } = school_unit;
    this.state = {
      form: {
        value: {
          va_code: va_code,
          unit_code: unit_code,
          levels: levels_id,
          organizations_id: organizations_id,
          total: 0,
          currents_year:"",
          academics_year:"",
          tabelpayments: [
            {
              payments_id: "",
              nominal: 0,
            },
          ],
        },
        error: {
          tabelpayments:[]
        },
      },
      param: {},
    };
  }

  componentDidMount() {
    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const paramTypes = ["classrooms", "levels"];
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    this.getYearsOfEducationsOptions({units_id: school_unit.id});
    this.getPrmPayments();
    // this._getPrmLevels({ filters: {units_id: [school_unit.id], organizations_id: organizations_id } });
    // this._getStudentSPPOptions({units_id: [school_unit.id] });
  }

  _onFormChange(event) {
    const { user } = this.props;
    const { school_unit } = user;
    const { form } = this.state;
    const { tabelpayments, classrooms_id } = form.value;
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;

    if (name === "classrooms_id"){
      // kosongkan siswa
      this.setState(prevState => ({
        ...prevState,
        form: {
          ...prevState.form,
          value : {
            ...prevState.form.value,
            students: [],
          }
        },
      }))

    }

    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === "checkbox") {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName] || [];
        newListError = prevState.form.error[fieldName] || [];

        if (name === "payments_id") {
          newList[arrayPosition].error_payments = "";
          {map(tabelpayments, (data, idx) => {
            if (data.payments_id == value) {
              formattedValue = ""
              newList[arrayPosition].error_payments = "Nama pembayaran sudah dipilih";
            }
          })}
        }

        if (name === "nominal") {
          var toNum = value
          do{
            toNum=toNum.replace(",", "")
          }
          while(toNum.indexOf(",") != -1)
          formattedValue=Number(toNum)
        }

        newList[arrayPosition][name] = formattedValue;
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
          },
        },
      };
    }, () => {
      if (name === 'levels') {
        this._getParamOptionsAcademics( 'classrooms', {levels_id: value, organizations_id: 3 } );
      }
      if (name === 'classrooms_id') {
        this._getStudentSPPOptions({ units_id: [school_unit.id], filters: { classrooms_id: [value], organizations_id: 3 }} );
      }
    });
  }

  _onRemoveMulti(selectedList, removedItem) {
    this._onFormChange({
      target: {
        name: removedItem,
        value: selectedList,
        dataset: {},
      },
    });
  }

  _onChangeMulti(value, name) {
    this._onFormChange({
      target: {
        name,
        value,
        dataset: {},
      },
    });
  }

  _onAddList(data) {
    const { form } = this.state;
    const { tabelpayments } = form.value;

    tabelpayments.push({
      payments_id: "",
      nominal: 0,
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          tabelpayments,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }

  _onDeleteList(idx) {
    this.setState((prevState) => {
      const list = prevState.form.value.tabelpayments;
      list.splice(idx, 1);
      return {
        ...prevState,
        tabelpayments: list,
      };
    });
  }

  async onSubmit() {
    const { history, handleSaveRutinClassrooms } = this.props;
    const { form } = this.state;
    const { value } = form;

    if(form.value.total < 0) {
      errorAlert({
        title: 'Total tidak boleh minus',
        errorMessage: 'Mohon di cek kembali'
      });
    } 
    else {
      let canSaveA = true;
      const dataForm = form.value;
      const lengthdata = dataForm.tabelpayments.length
      if(dataForm.currents_year && dataForm.levels && (dataForm.students) && lengthdata){
        if(dataForm.students.length > 0) {
          map(dataForm.tabelpayments, (data, idx) => {
            if(!data.payments_id || !data.nominal) {
              canSaveA = false;
              errorAlert({
                title: 'Harap lengkapi data form',
                errorMessage: 'Bagian yang harus diisi ditandai dengan tanda bintang (*)'
              });
            } else if (data.nominal == 0) {
              canSaveA = false;
              errorAlert({
                title: 'Nominal Tidak Boleh "0"',
                errorMessage: 'Mohon hapus pembayaran yang tidak terpakai.'
              });
            }
          })
        }
      } else {
        canSaveA = false;
        errorAlert({
          title: 'Harap lengkapi data form',
          errorMessage: 'Bagian yang harus diisi ditandai dengan tanda bintang (*)'
        });
      }
  
      if (canSaveA){
        await handleSaveRutinClassrooms(value);
        history.push("/dashboard/tagihan-siswa/create-invoice");
      } 
    }
  }

  async getYearsOfEducationsOptions(filters={}) {
    const { getYearsOfEducationsOptions } = this.props;
    const res = await getYearsOfEducationsOptions(filters);

    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date)
      if (date > start_date) {
        // tahun ajaran selanjutnya
        if (res[idx+1]) {
          this.setState(prevState => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                nextyear: res[idx+1].value,
              }
            }
          }))
        }
      }
    });

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = organizations_id;
      filters.units_id = school_unit.id;
      // filters.levels_id = levels_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getPrmLevels(filters = {}) {
    const { getPrmLevels, user } = this.props;
    const { organizations_id, school_unit } = user;
    const res = await getPrmLevels(filters);
    filters.organizations_id = organizations_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        levels: res,
      },
    }));
  }

  async _getStudentSPPOptions(filters={}) {
    const { getStudentSPPOptions } = this.props;
    const res = await getStudentSPPOptions(filters);
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
  }

  async getPrmPayments() {
    const { getPrmPayments } = this.props;
    const res = await getPrmPayments();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_payments: res,
      },
    }));
  }

  render() {
    const { form, param } = this.state;
    const { tabelpayments, nextyear } = form.value;

    form.value.total = 0;
    {map(tabelpayments, (list, idx) => {
      if (list.payments_id && !isEmpty(param.prm_payments)) {
        list.is_discount = param.prm_payments.find(i => i.value == list.payments_id).is_discount
        // if (list.payments_id == 42 || list.payments_id == 43) {
        if (list.is_discount == 1) {
          form.value.total-=Number(list.nominal)
        } else {
          form.value.total+=Number(list.nominal)
        }
      }
    })}

    if(form.value.total < 0) {
      form.error.total = "Total minus."
    } else {
      delete form.error.total;
    }
    
    form.value.currents_year = form.value.currents_year ? form.value.currents_year : nextyear

    return (
      <div className="student-list">
        <div className="budget__title">
          <h1>Invoice SPP per Kelas </h1>
          <hr></hr>
        </div>
        <form>
          <div className="result__content">
            <div className="nilai-kognitif__custom-form">
              <div className="kode-matpel__custom-form-row">
                <div className="kode-matpel__custom-form-row__field">
                  <Select
                    name="currents_year"
                    label="Tahun Ajaran *"
                    onChange={this._onFormChange}
                    data={param.periods}
                    placeholder={"Pilihan"}
                    value={form.value.currents_year || ""}
                    error={form.error.currents_year}
                    isRequired
                  />
                </div>
                <div className="kode-matpel__custom-form-column__field">
                  <Select
                    name="levels"
                    label="Pilih Unit"
                    placeholder={"Pilihan"}
                    data={param.levels}
                    onChange={this._onFormChange}
                    value={form.value.levels}
                    error={form.error.levels || ""}
                    disabled
                  />
                </div>
              </div>
              <div className="kode-matpel__custom-form-row">
                <div className="kode-matpel__custom-form-row__field">
                  {(form.value.levels) && (
                    <Select
                      placeholder="Pilih Kelas"
                      label="Kelas"
                      name="classrooms_id"
                      value={form.value.classrooms_id}
                      data={param.classrooms}
                      onChange={this._onFormChange}
                    />
                  )}
                </div>
                <div className="kode-matpel__custom-form-column__field">
                </div>
              </div>
              <br></br>
              <div className="kode-matpel__custom-form-row">
                <div className="kode-matpel__custom-form-column__field">
                  {(form.value.classrooms_id) && (
                    <>
                      <p>Siswa *</p>
                      <SelectMultiple
                        name="students"
                        style={{ width: "100%", fontSize:'25px' }}
                        options={param.student}   
                        // disable	={form.value.classrooms ? false : true}
                        displayValue="label" // Property name to display in the dropdown options
                        showCheckbox={true}
                        values={form.value.students}
                        errors={form.error.students}
                        onRemove={(value) => this._onRemoveMulti(value, "students")}
                        onSelect={(value) => this._onChangeMulti(value, "students")}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <table className="manage-rapbs-plafon__form-table table-invoice">
            <thead>
              <tr>
                <th colSpan="2">Data *</th>
              </tr>
            </thead>
            <tbody>
              {map(tabelpayments, (list, idx) => (
                <tr key={`plafon_journal_data__${idx}`}>
                  <td>
                    <div className="kode-matpel__custom-form-row">
                      <div className="kode-matpel__custom-form-column__field">
                        <Select
                          label="Nama Pembayaran *"
                          name="payments_id"
                          onChange={this._onFormChange}
                          placeholder="Pilihan"
                          data={param.prm_payments}
                          value={list.payments_id}
                          error={list.error_payments}
                          // isRequired
                          data-input-array
                          arrayPosition={idx}
                          data-array-position={idx}
                          data-field-name="tabelpayments"
                        />
                      </div>
                      <div className="kode-matpel__custom-form-column__field" style={{flexDirection: "unset"}}>
                        <label style={{paddingTop: "30px", paddingRight: "20px"}}>Rp.</label> 
                        <Input
                          // type="number"
                          name="nominal"
                          label="Nominal *"
                          onChange={this._onFormChange}
                          value={list.nominal}
                          // error={form.error.nominal}
                          // isRequired
                          data-input-array
                          arrayPosition={idx}
                          data-array-position={idx}
                          data-field-name="tabelpayments"
                          isNumber
                        />
                      </div>
                    </div>

                  </td>
                  <td>
                    <Button onClick={() => this._onDeleteList(idx)} icon="icon-trash-o"></Button>
                  </td>
                  
                </tr>
                
              ))}
            </tbody>
            <tfoot>
              <tr className="manage-rapbs-plafon__add-more">
                <td colSpan={2}>
                  <div>
                    <Button icon="icon-plus" onClick={this._onAddList}></Button>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>                    
                    <div className="kode-matpel__custom-form-row">
                      <div className="kode-matpel__custom-form-row__field">
                      </div>
                      
                      <div className="kode-matpel__custom-form-column__field" style={{flexDirection: "unset"}}>
                        <label style={{paddingTop: "30px", paddingRight: "20px"}}>Rp.</label> 
                        <Input
                          name="total"
                          label="Total"
                          onChange={this._onFormChange}
                          value={form.value.total}
                          isNumber
                        />
                      </div>
                    </div>
                  </div>
                </td>
                <td></td>
              </tr>
            </tfoot>
          </table>
          <br></br>
          <div className="student-list__header">
            <div className="student-list__button">
              <Button type="button" title="Simpan" onClick={this.onSubmit} />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
CreateInvoiceKelas.propTypes = {
};
CreateInvoiceKelas.defaultProps = {
};
